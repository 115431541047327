@import "theme/module";

$padding: 16px;
$highlight: $color-arcaneus-red;

.hello {
    font-size: px-to-rem(24px);
    color: white;
}

.container {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.centered {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    :global(.icon) {
        width: px-to-rem(120px);
        height: px-to-rem(120px);
        svg path {
            fill: #ff1561;
        }
        animation: pulse-half 3s infinite;
    }
}

.overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.line {
    position: absolute;
    z-index: 10;
    top: 0;
    left: px-to-rem(40px);
    width: 2px;
    height: 100vh;
    background-color: red;
}

.logo {
    font-family: "Futura";
    font-size: px-to-rem(18px);
    padding-left: px-to-rem(8px);
    color: rgba(255, 255, 255, 0.7);
    :global(.icon) {
        width: px-to-rem(20px);
        height: px-to-rem(20px);
        margin-right: px-to-rem(8px);
        svg path {
            fill: #ff1561;
        }
    }

    // if the product menu is shown.. change color
    &.show {
        :global(.icon) {
            svg path {
                fill: rgba(255, 255, 255, 0.7);
            }
        }
    }
}

.headline {
    position: relative;
    padding: px-to-rem(40px);
    padding-top: px-to-em(90px);

    line-height: 1.25;
    font-family: "Roboto";
    font-weight: 300;
    font-size: px-to-rem(24px);

    span {
        font-family: "Futura";
        font-size: larger;
    }

    b {
        color: $highlight;
    }

    user-select: none;

    &.mobile {
        font-size: px-to-rem(20px);
    }
}

.streamVideo {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 1;
    filter: contrast(2) grayscale(0.2);
}

.typingVideo {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.7;
    filter: contrast(1) grayscale(0.2);
}

img.ai {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.8;
    filter: grayscale(0) sepia(1);

    animation: pulsing-grayscale 5s infinite;
}

.footer {
    position: fixed;
    font-family: "Roboto";
    bottom: 0;
    opacity: 0.5;
    height: px-to-rem(24px);
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    font-size: px-to-rem(12px);
}
