@import "theme/module";

.view {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: white;
    color: black;

    background: beige;
    color: black;
    opacity: 1;

    user-select: none;

    section {
        padding: px-to-em(90px) px-to-em(40px);

        p {
            font-family: "Roboto";
            font-size: px-to-em(16px);
            line-height: 1.25;
            margin-bottom: px-to-em(8px);
            opacity: 0.8;
        }

        .sectionHeader {
            font-family: "Bullfight";
            font-size: px-to-em(24px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: px-to-em(30px);
            margin-bottom: px-to-em(16px);

            :global(.icon) {
                width: px-to-em(24px);
                height: px-to-em(24px);
                transform-origin: center;
                transform: rotate(45deg);
                svg path {
                    fill: rgba(0, 0, 0, 0.5);
                }
                &:hover {
                    svg path {
                        fill: rgba(0, 0, 0, 1);
                    }
                }

                animation: pulse-half 2s infinite;
            }
        }
    }

    top: 100%;

    &.mobile {
        section {
            padding: px-to-em(90px) px-to-em(16px);
        }
    }
}

.product {
    opacity: 0.8;

    user-select: none;

    animation: fade-in linear 1s;

    p {
        font-family: "Roboto";
        font-size: px-to-em(16px);
        line-height: 1.25;
        margin-bottom: px-to-em(16px);
        font-weight: 300;

        strong {
            font-weight: 400;
        }
    }

    h2 {
        font-family: "Bullfight";
        font-size: px-to-em(16px);
        text-decoration: underline;
        margin-top: px-to-em(24px);
        margin-bottom: px-to-em(16px);
    }

    ul {
        margin-top: px-to-em(8px);
        list-style: circle;
        font-size: px-to-em(16px);

        li {
            font-family: "Roboto";
            font-size: px-to-em(14px);
            line-height: 1.25;
            font-weight: 300;
            margin-bottom: px-to-em(16px);

            strong {
                font-weight: 400;
            }
        }
    }

    a {
        text-decoration: none;
        color: $color-electric-pink;
    }
}
