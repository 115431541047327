@import "theme/module";

.nav_slider {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    background-color: black; //$surface_accent;
    top: px-to-em(48px);
    left: 100%;

    transition: left 0.25s linear;

    &.open {
        left: px-to-em(0px);
    }
}

.nav_box {
    display: flex;
    flex-direction: column;
    margin-top: px-to-rem(80px);

    font-family: "Roboto";
    font-size: px-to-em(15px);
    font-weight: 200;

    > div {
        font-size: px-to-em(20px);
        text-wrap: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: px-to-em(40px);
        padding-left: px-to-rem(50px);

        margin-bottom: px-to-rem(8px);
        align-items: center;

        border-bottom: solid 1px rgba(255, 255, 255, 0.25);

        &:hover {
            background-color: rgba(255, 255, 255, 0.25);
        }

        &:last-child {
            border: none;
        }

        &.selected {
            font-weight: 400;
            background-color: black;
        }

        span {
            padding-left: px-to-em(4px);

            :global(.icon) {
                width: px-to-em(16px);
                height: px-to-em(16px);
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                transform-box: fill-box;
                transition: transform 0.25s linear;

                &.spin {
                    transform: rotate(45deg);
                }
            }
        }
    }
}
