@keyframes pulse {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes outline-pulse {
    0% {
        outline-width: 0;
    }
    50% {
        outline-width: 10px;
    }
    100% {
        outline-width: 0;
    }
}

@keyframes pulse-half {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse-quarter {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse-full {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes opacity-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes opacity-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes animated-gradient {
    0% {
        background-position: 0% 70%;
    }
    50% {
        background-position: 100% 30%;
    }
    100% {
        background-position: 0% 80%;
    }
}

@keyframes pulsing-grayscale {
    0% {
        filter: grayscale(0.2);
    }
    50% {
        filter: grayscale(0.8);
    }
    100% {
        filter: grayscale(0.2);
    }
}
// fade

.form-fade-animate-exit {
    opacity: 1;
}

.form-fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
}

.form-fade-exit-done {
    opacity: 0;
}

.form-fade-enter {
    opacity: 0;
}

.form-fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
}

.form-fade-enter-done {
    opacity: 1;
}

// slide

// .form-slide-animate-exit {
//     opacity: 1;
// }

.form-slide-exit {
    left: 0;
}

.form-slide-exit-active {
    left: -100%;
    transition: left 0.5s;
}

.form-slide-exit-done {
    left: -100%;
}

.form-slide-enter {
    left: 100%;
}

.form-slide-enter-active {
    left: 0;
    transition: left 0.5s;
}

.form-slide-enter-done {
    left: 0;
}
