@import "theme/module";

.icon {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: px-to-rem(24px);
    height: px-to-rem(24px);

    svg {
        width: 100%;
        height: 100%;
        path {
            fill: $white;
            // fill: $indigo_60;
        }
    }
}
