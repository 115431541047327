@import "theme/module";

.logo {
    font-family: "Futura";
    font-size: px-to-em(18px);
    padding-left: px-to-em(8px);
    color: rgba(255, 255, 255, 0.7);
    :global(.icon) {
        width: px-to-em(20px);
        height: px-to-em(20px);
        margin-right: px-to-em(8px);
        svg path {
            fill: #ff1561;
        }
    }

    // if the product menu is shown.. change color
    &.show {
        :global(.icon) {
            svg path {
                fill: rgba(255, 255, 255, 0.7);
            }
        }
    }
}

.nav {
    position: fixed;
    top: 0;
    font-family: "Roboto";
    font-size: px-to-em(15px);
    font-weight: 200;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    height: px-to-em(50px);
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid white;

    &.show {
        background-color: rgba(0, 0, 0, 0.6);
    }

    > div {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        &:hover {
            background-color: rgba(0, 0, 0, 0.25);
        }

        &:first-child {
            justify-content: flex-start;
            border-right: 1px solid white;
            padding-right: px-to-em(16px);
        }
        &:last-child {
            border-left: 1px solid white;
        }

        &.selected {
            font-weight: 400;
            background-color: black;
        }

        .mobile {
            height: px-to-em(60px);
            span {
                padding-left: px-to-em(4px);

                :global(.icon) {
                    width: px-to-em(32px);
                    height: px-to-em(32px);
                    transform: rotate(0deg);
                    transform-origin: 50% 50%;
                    transform-box: fill-box;
                    transition: transform 0.25s linear;

                    &.spin {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        span {
            padding-left: px-to-em(4px);

            :global(.icon) {
                width: px-to-em(16px);
                height: px-to-em(16px);
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                transform-box: fill-box;
                transition: transform 0.25s linear;

                &.spin {
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.nav:global(.portrait) {
    justify-content: flex-start;

    .logo {
        width: 100%;
    }

    .menu {
        width: px-to-em(80px);
    }
}
