@import "theme/module";

.quote {
    border: solid 1px red;
    font-family: "Bullfight";
    line-height: 1.15;
    position: relative;

    margin-top: px-to-rem(40px);
    margin-left: px-to-rem(40px);
    margin-right: px-to-rem(40px);
    margin-bottom: px-to-rem(80px);

    max-width: px-to-rem(720px);
    background: rgba(0, 0, 0, 0.7);
    border-radius: px-to-rem(10px);
    padding: px-to-rem(16px);
    opacity: 0.8;

    user-select: none;

    .copy {
        font-size: px-to-rem(30px);
        line-height: 1.25;
        margin-bottom: px-to-rem(24px);
    }

    .credits {
        font-size: px-to-rem(14px);
        text-align: right;
        animation: fade-in 5s;
    }

    &.mobile {
        .copy {
            font-size: px-to-rem(24px);
        }

        .credits {
            font-size: px-to-rem(12px);
        }
    }
}
